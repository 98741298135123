import { Component, OnInit } from "@angular/core";
import { OurService } from "../../services/ourservices.service";
import { EventService } from '../../services/event.service';
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  styleUrls: ["./projects.component.scss"],
})
export class ProjectsComponent implements OnInit {
  focus: any;
  focus1: any;
  public functionData = [];
  public functionImages = [];
  // public isCollapsed = false;
  public isCollapsed: boolean[] = []
  public isLoading;
  constructor(private ourService: OurService,
    private EventService: EventService,) { }

  ngOnInit() {
    this.getFunctionDetails();
  }

  getFunctionDetails() {
    this.isLoading = true;
    this.EventService.getEvents(false).subscribe((data) => {
      this.isLoading = false;
      this.functionData = data.reverse();
      this.getFunctionImagesById(data[0].id);
    });
  }

  getImageById(id) {
    this.getFunctionImagesById(id);
  }
  getFunctionImagesById(id) {
    this.isLoading = true;
    this.EventService.getEvent(id).subscribe((data) => {
      this.isLoading = false;
      data.map(i => {
        i.data = "data:image/jpeg;base64," + i.data;
      });
      this.functionImages = data;
    });
  }


}
