import { Component, OnInit } from "@angular/core";
import { OurService } from "../../services/ourservices.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.scss"],
})
export class ServicesComponent implements OnInit {
  public isExpand = false;
  collapseId: any;
  focus: any;
  focus1: any;
  serviceType: number=0;
  pageTitle: string;
  projectData = [];
  routeParams: any;

  constructor(
    private ourService: OurService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.routeParams = route.snapshot.params;
    this.serviceType = Number(this.routeParams.serviceType);
   
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.serviceType = params.serviceType;
    });
  }

  handleCollapse(id) {
    this.router.navigate(["/projectDetail/" + id]);
  }
}
