import { Component, OnInit } from "@angular/core";
import { OurService } from "../../services/ourservices.service";
import { EventService } from '../../services/event.service';
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-reviews",
  templateUrl: "./reviews.component.html",
  styleUrls: ["./reviews.component.scss"],
})
export class ReviewsComponent implements OnInit {
  focus: any;
  focus1: any;
  public functionData = [];
  public functionImages = [];
  // public isCollapsed = false;
  public isCollapsed: boolean[] = []
  public isLoading;
  constructor(private ourService: OurService,
    private EventService: EventService,) { }

  ngOnInit() {
    this.getReviewsDetails();
  }

  getReviewsDetails() {
    this.isLoading = true;
    this.EventService.getReviews(false).subscribe((data) => {
      debugger;
      this.isLoading = false;
      this.functionData = data.reverse();
    });
  }
}
